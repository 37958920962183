import React, { useState, useContext, useRef, useEffect } from 'react';
import { Navbar, Container, Nav, Button, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faArrowRightFromBracket, faBars, faFileLines } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import horse from './img/horse.png';
import Login from './login';
import Email from './Email';
import NewComp from './components/NewComp';
import Abfahrtsplaner from './components/Map';
import Settings from './components/Settings';
import Price from './components/Price';
import Impressum from './components/Impressum';
import Calender from './components/EventCalendar';
import AGB from './components/AGB';
// import Kalender from './components/Kalender';
import { UserContext } from './MainPage';

const CNavbar = ({ onUserLogin, onUserLogout }) => {

  const { user } = useContext(UserContext);  
  const [showLogin, setShowLogin] = useState(false);
  const [showEmail, setshowEmail] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setNavbarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLinkClick = (componentName) => {
    setActiveComponent(componentName);
    setShowLogin(false);
    setshowEmail(false);
    setNavbarOpen(false);
  };

  const handleLoginButtonClick = () => {
    setShowLogin(true);
    setshowEmail(false);
    setActiveComponent(null);
    setNavbarOpen(false);
  };

  const handleEmailButtonClick = () => {
    setShowLogin(false);
    setshowEmail(true);
    setActiveComponent(null);
    setNavbarOpen(false);
  };

  const handleSettingsLinkClick = () => {
    setShowLogin(false);
    setshowEmail(false);
    setActiveComponent('Settings');
    setNavbarOpen(false);
  };

  const handleNavbarToggle = () => {
    setNavbarOpen(!navbarOpen);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Team':
        return <NewComp thema={'team'} headText={'Unser Team'} onClose={() => setActiveComponent(null)} />;
      case 'Entstehung':
        return <NewComp thema={'entstehung'} headText={'Die Entstehung unseres sReitackerl'} onClose={() => setActiveComponent(null)} />;
      case 'Reitpädagogik':
        return <NewComp thema={'reitpaed'} headText={'Reitpädagogik'} onClose={() => setActiveComponent(null)} />;
      case 'Ostern':
        return <NewComp thema={'ostern'} headText={'Ostern'} onClose={() => setActiveComponent(null)} />;
      case 'Halloween':
        return <NewComp thema={'halloween'} headText={'Halloween'} onClose={() => setActiveComponent(null)} />;
      case 'Fasching':
        return <NewComp thema={'fasching'} headText={'Fasching'} onClose={() => setActiveComponent(null)} />;
      case 'Schulbeginn':
        return <NewComp thema={'schulbeginn'} headText={'Schulbeginn'} onClose={() => setActiveComponent(null)} />;
      case 'Mobbing':
        return <NewComp thema={'mobbing'} headText={'Mobbing'} onClose={() => setActiveComponent(null)} />;
      case 'Selbstbewusstsein': 
        return <NewComp thema={'selbstbewusstsein'} headText={'Selbstbewusstsein'} onClose={() => setActiveComponent(null)} />;
      case 'Konzentration':
        return <NewComp thema={'konzentration'} headText={'Konzentration'} onClose={() => setActiveComponent(null)} />;
      case 'Ausritte':
        return <NewComp thema={'ausritte'} headText={'Ausritte'} onClose={() => setActiveComponent(null)} />;
      case 'Waldpaedagogik':
        return <NewComp thema={'waldpaedagogik'} headText={'Waldpädagogik'} onClose={() => setActiveComponent(null)} />;
      case 'Ausbildung':
        return <NewComp thema={'ausbildung'} headText={'Ausbildung'} onClose={() => setActiveComponent(null)} />;
      case 'Kräuterritt':
        return <NewComp thema={'kraeuterritt'} headText={'Kräuterritt'} onClose={() => setActiveComponent(null)} />;
      case 'Schnappschüsse':
        return <NewComp thema={'schnappschuesse'} headText={'Schnappschüsse'} onClose={() => setActiveComponent(null)} />;
      case 'Anfahrt':
        return <Abfahrtsplaner onClose={() => setActiveComponent(null)}/>;
      case 'Settings':
        return <Settings onClose={() => setActiveComponent(null)} />;
      case 'Price':
        return <Price onClose={() => setActiveComponent(null)} />;
      case 'Impressum':
        return <Impressum onClose={() => setActiveComponent(null)}  openContact={() => handleEmailButtonClick()} />;
      case 'Allgemeine':
        return <AGB onClose={() => setActiveComponent(null)} />;
      case 'Calender':
        return <Calender onClose={() => setActiveComponent(null)} />;
      // case 'Kalender':
      //   return <Kalender onClose={() => setActiveComponent(null)} />;
      default:
        // console.log("Error: NOT FOUND");
        return;
    }
  };

  const openAGBs = () =>{
    console.log("2");
  }
  return (
    <>
      <Navbar ref={navbarRef} bg="dark" expand="lg" expanded={navbarOpen} onToggle={handleNavbarToggle}>
        <Container>
          <Navbar.Brand>
            <img
              src={horse}
              alt="Horse"
              className="d-inline-block align-top main-media"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarNavDropdown">
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarNavDropdown">
            <Nav className="me-auto">
              <Nav.Link onClick={() => handleLinkClick('Team')}>
                Unser Team
              </Nav.Link>
              <Nav.Link onClick={() => handleLinkClick('Entstehung')}>
                Entstehung
              </Nav.Link>
              <Nav.Link onClick={() => handleLinkClick('Reitpädagogik')}>
                Reitpädagogik
              </Nav.Link>
              <NavDropdown title="Events" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => handleLinkClick('Ostern')}>Ostern</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLinkClick('Halloween')}>Halloween</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLinkClick('Fasching')}>Fasching</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLinkClick('Schulbeginn')}>Schulbeginn</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLinkClick('Ausritte')}>Geführte Ausritte</NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleLinkClick('Waldpaedagogik')}>Waldpädagogik</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown title="Intensivtage" id="nested-dropdown">
                  <NavDropdown.Item onClick={() => handleLinkClick('Mobbing')}>Mobbing</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleLinkClick('Selbstbewusstsein')}>Selbstbewusstsein</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleLinkClick('Konzentration')}>Konzentration</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
              <NavDropdown title="Halbtagesritte" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => handleLinkClick('Kräuterritt')}>Kräuterritt</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link onClick={() => handleLinkClick('Schnappschüsse')}>
                Schnappschüsse
              </Nav.Link>
              <Nav.Link onClick={() => handleLinkClick('Ausbildung')}>
                Ausbildung
              </Nav.Link>
              <Nav.Link onClick={() => handleLinkClick('Price')}>
                Preise
              </Nav.Link>
              {user && (
              <Nav.Link onClick={() => handleLinkClick('Anfahrt')}>
                Anfahrt
              </Nav.Link>
              )}
              <Nav.Link onClick={() => handleLinkClick('Impressum')}>
                Impressum
              </Nav.Link>
              {user && (
              <Nav.Link onClick={() => handleLinkClick('Calender')}>
                Kalender 1
              </Nav.Link>
              )}
              {/* {user && (
              <Nav.Link onClick={() => handleLinkClick('Kalender')}>
                Kalender 2
              </Nav.Link>
              )} */}
            </Nav>
            <div className="text-right">
              {user ? (
                <>
                  <Button variant="outline-info" onClick={handleSettingsLinkClick}>
                    <FontAwesomeIcon icon={faLock} /> Settings
                  </Button>
                  <Button variant="outline-danger" onClick={onUserLogout}>
                    <FontAwesomeIcon icon={faArrowRightFromBracket} /> Abmelden
                  </Button>
                </>
              ) : (
                <Button variant="outline-primary" onClick={handleLoginButtonClick}>
                Anmelden
                </Button>
              )}
              <Button variant="outline-success" onClick={handleEmailButtonClick}>
                Contact
              </Button>
              <Button variant="outline-danger" onClick={() => handleLinkClick('Allgemeine')}>
                <FontAwesomeIcon icon={faFileLines} /> AGBs
              </Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showLogin && <Login showModal={showLogin} onClose={() => setShowLogin(false)} onUserLogin={onUserLogin} />}
      {showEmail && <Email showModal={showEmail} onClose={() => setshowEmail(false)} />}
      {renderComponent()}
    </>
  );
};

export default CNavbar;
