import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import './css/modal.css';

const AGB = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const handleCaptchaChange = (value) => {
        setCaptchaVerified(!!value);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async () => {
        if (!firstName || !lastName || !validateEmail(email) || !checked || !captchaVerified) {
            setMessage('Bitte geben Sie Vor- und Nachnamen sowie eine gültige E-Mail-Adresse ein, bestätigen Sie die AGB und lösen Sie das CAPTCHA.');
            return;
        }

        setIsSubmitting(true);  // Deaktivieren des Buttons

        try {
            const response = await axios.post('https://kidsauszeit.at:5000/sendAGBEmail', {
                email: email,
                firstName: firstName,
                lastName: lastName
            });

            if (response.status === 200) {
                setMessage('E-Mail erfolgreich gesendet.');
            } else {
                setMessage('Fehler beim Senden der E-Mail.');
            }
        } catch (error) {
            setMessage('Fehler beim Senden der E-Mail.');
        }

        // Wartezeit, bevor der Button wieder aktiviert wird
        setTimeout(() => setIsSubmitting(false), 3000);
    };

    return (
        <Modal show={true} onHide={onClose} dialogClassName="slideshow-modal slideshow-modal-AGBs">
            <Modal.Header closeButton>
                <Modal.Title>Allgemeine Geschäftsbedingungen</Modal.Title>
            </Modal.Header>
            <Modal.Body class="agb-body">
                <div>
                    <iframe className="iframe-agb" src="https://docs.google.com/document/d/e/2PACX-1vSQSpBbSuJR0f3qWwUTBVPNFk-7fRrtIIQ08Ea7A8i4ulafCsUbOpV8bF4_asl_dEsHYhmBA-49WkSR/pub?embedded=true"></iframe>
                </div>

                <div className="form-container">
                    <input 
                        type="text" 
                        placeholder="Vorname" 
                        value={firstName} 
                        onChange={(e) => setFirstName(e.target.value)} 
                        className="name-input"
                    />
                    <input 
                        type="text" 
                        placeholder="Nachname" 
                        value={lastName} 
                        onChange={(e) => setLastName(e.target.value)} 
                        className="name-input"
                    />
                    <input 
                        type="email" 
                        placeholder="E-Mail-Adresse" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        className="email-input"
                    />
                    <div className="checkbox-container">
                        <input 
                            type="checkbox" 
                            checked={checked} 
                            onChange={(e) => setChecked(e.target.checked)} 
                            className="agb-checkbox"
                        />
                        <label className="checkbox-label">Ich habe die AGB gelesen und akzeptiere sie.</label>
                    </div>
                    
                    <ReCAPTCHA
                        sitekey="6Ld83IgjAAAAAI9dLDLvGueF-qJqaAzJ9uzewaJB"  // Ersetze dies durch deinen reCAPTCHA-Site-Schlüssel
                        onChange={handleCaptchaChange}
                        class="recaptcha-container"
                    />
                    
                    <Button 
                        onClick={handleSubmit} 
                        className="submit-button" 
                        disabled={isSubmitting}  // Button wird deaktiviert, wenn isSubmitting true ist
                    >
                        Absenden
                    </Button>
                    {message && <p className="message">{message}</p>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AGB;
