import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope, faCheck, faTimes, faPhone, faPerson } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import './main.css';

const Email = ({ showModal, onClose }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name') setName(value);
    else if (name === 'phoneNumber') setPhoneNumber(value);
    else if (name === 'email') setEmail(value);
    else if (name === 'message') setMessage(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !phoneNumber || !email || !message || !recaptchaValue) {
      setErrorMessage('Bitte alle Felder ausfüllen und das reCAPTCHA bestätigen.');
      return;
    }

    try {
      setLoading(true);

      const response = await axios.post('https://kidsauszeit.at:5000/sendEmail', {
        name,
        phoneNumber,
        email,
        message,
        recaptchaValue,
      });

      if (response.data.success) {
        setSendSuccess(true);
      } else {
        setSendSuccess(false);
      }
    } catch (error) {
      console.error('Fehler beim Senden der E-Mail:', error);
      setSendSuccess(false);
    } finally {
      setLoading(false);
      setShowForm(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-email')) {
      onClose();
      setErrorMessage('');
      setShowForm(true);
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div className={`modal-email ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }} onClick={handleOverlayClick}>
      <form className="email-modal-content" onSubmit={handleSubmit} autoComplete="off">
      {(showForm && !loading) && (
          <>
            <h2>Kontakt Aufnehmen</h2>
            <div className="email-content">
              <div className="icon">
                <FontAwesomeIcon icon={faPerson} />
              </div>
              <input type="text" placeholder="Name" name="name" value={name} onChange={handleChange} autoComplete="off" />
            </div>
            <div className="email-content">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <input type="tel" placeholder="Telefonnummer" name="phoneNumber" value={phoneNumber} onChange={handleChange} autoComplete="off" />
            </div>
            <div className="email-content">
              <div className="icon">
                <FontAwesomeIcon icon={faAt} />
              </div>
              <input type="email" placeholder="E-Mail" name="email" value={email} onChange={handleChange} autoComplete="off" />
            </div>
            <div className="email-content">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <textarea placeholder="Wie kann man Ihnen weiterhelfen?" rows="6" name="message" value={message} onChange={handleChange} autoComplete="off" />
            </div>
            <div className="email-content captcha">
              <ReCAPTCHA sitekey="6Ld83IgjAAAAAI9dLDLvGueF-qJqaAzJ9uzewaJB" onChange={handleRecaptchaChange} />
            </div>
            <input type="submit" value="Send Message" />
          </>
        )}
        {loading ? (
          <div className="loading-bar">Loading</div>
        ) : (
          <>
            {sendSuccess === true && (
              <div className="loading-bar-success">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            )}
            {sendSuccess === false && (
              <div className="loading-bar-failure">
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
            {errorMessage && <p className="errorMessage">{errorMessage}</p>}
          </>
        )}
      </form>
    </div>
  );
};

export default Email;
