import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './main.css';

const Login = ({ showModal, onClose, onUserLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password) {
      setErrorMessage('Bitte Benutzername und Passwort eingeben');
      return;
    }

    try {
      const response = await axios.post('https://kidsauszeit.at:5000/login', { username, password, rememberMe });
      const { success, username: user, rank: rank } = response.data;
      if (success) {
        if(rememberMe){
          document.cookie = `token=${response.data.token}; max-age=${7 * 24 * 60 * 60}; path=/`;
        }else{
          document.cookie = `token=${response.data.token}; max-age=${24 * 60 * 60}; path=/`;
        }
        onUserLogin(user, rank);
        onClose();
      }
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 404)) {
        setErrorMessage(error.response.data.error || 'Falscher Benutzername oder Passwort');
      } else {
        setErrorMessage(error.response.data.error || 'Server-Fehler');
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-login')) {
      onClose();
      setErrorMessage('');
    }
  };

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div className={`modal-login ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }} onClick={handleOverlayClick}>
      <form className="modal-login-content animate box" onSubmit={handleLogin} autoComplete="off">
        <div className="form">
          <h2>Sign In</h2>
          <div className="content">
            <div className="icon">
              <FontAwesomeIcon icon={faAt} />
            </div>
            <input type="text" placeholder="Enter Username" value={username} onChange={(e) => setUsername(e.target.value)} autoComplete="off"/>
          </div>
          <div className="content">
            <div className="icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <input type={showPassword ? 'text' : 'password'} placeholder="Enter Password" id="pass" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete="off"/>
            <span className="show-hide" onClick={togglePasswordVisibility}>
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} id="showHide" />
            </span>
          </div>
          <div className="remember-me">
            <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={handleRememberMeChange} />
            <label htmlFor="rememberMe">Angemeldet bleiben</label>
          </div>
          <input type="submit" value="Login"></input>
        </div>
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      </form>
    </div>
  );
};

export default Login;
