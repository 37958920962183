import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import './css/modal.css';

const Impressum = ({ onClose, openContact }) => {
    return (
        <Modal show={true} onHide={onClose} dialogClassName="slideshow-modal-Imprint">
        <Modal.Header closeButton>
            <Modal.Title>Impressum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='txt'>
                <p><strong>Verein:</strong><br />
                Kids Auszeit<br />
                ZVR-Zahl: 1302201952</p>

                <p><strong>Vereinssitz:</strong><br />
                Kehbrunn 24<br />
                2802 Hochwolkersdorf<br />
                Österreich</p>

                <p><strong>Kontakt:</strong><br />
                Telefon: +43 680 4052396<br />
                E-Mail: info@kidsauszeit.at<br />
                Web: <a href="https://www.kidsauszeit.at" target="_blank" rel="noreferrer">https://www.kidsauszeit.at</a></p>

                <p><strong>Vertretungsberechtigt gemäß § 5 Vereinsgesetz:</strong><br />
                Obmann: Dominik Malek<br />
                Kassierin: Bianka Malek<br />
                Schriftführer: Markus Malek<br />
                (Alle wohnhaft unter der Vereinsadresse)</p>

                <p><strong>Vereinszweck:</strong><br />
                Der Verein „Kids Auszeit“ verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne der §§ 34 ff. BAO. Ziel ist die Förderung von Erholungsmöglichkeiten und Freizeitangeboten für Kinder und Jugendliche, insbesondere durch pferdegestützte Aktivitäten.</p>

                <p><strong>Haftungsausschluss:</strong><br />
                Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden. Wir übernehmen keine Haftung für externe Links – für deren Inhalte sind ausschließlich deren Betreiber verantwortlich.</p>

                <p><strong>Datenschutz:</strong><br />
                Informationen zur Verarbeitung personenbezogener Daten finden Sie in unserer&nbsp;
                <a className='privacy-link' href="https://kidsauszeit.at/privacy-policy.pdf" rel="noreferrer" target="_blank">Datenschutzerklärung</a>.</p>

                <p><strong>Mitgliedschaften:</strong><br />
                Der Verein ist im Zentralen Vereinsregister (ZVR) eingetragen und unterliegt der Aufsicht der Bezirkshauptmannschaft Wiener Neustadt.</p>
            </div>
            <Button variant='success' onClick={openContact}>Kontakt aufnehmen</Button>
        </Modal.Body>
        </Modal>
    );
};

export default Impressum;
