import React, { useState, useEffect, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { UserContext } from '../MainPage';
import './css/modal.css';

const EventCalendar = ({ onClose }) => {
  const { user, rank } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    description: '',
    start: '',
    end: '',
    allDay: false,
    color: '#3788d8'
  });
  const [calendarVisible, setCalendarVisible] = useState(true);
  const [validationError, setValidationError] = useState('');
  const [triedSubmit, setTriedSubmit] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://kidsauszeit.at:5000/api/events');
        const formatted = response.data.map(evt => ({
          id: evt.id,
          title: evt.title,
          start: evt.startDateTime,
          end: evt.endDateTime,
          allDay: !!evt.allDay,
          color: evt.color,
          extendedProps: {
            description: evt.details,
            user: evt.user,
          }
        }));
        setEvents(formatted);
      } catch (error) {
        console.error('Fehler beim Laden der Events:', error);
      }
    };
    fetchEvents();
  }, []);

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setShowViewModal(true);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setEditMode(false);
  };

  const handleDeleteEvent = async () => {
    if (!selectedEvent) return;
    try {
      await axios.delete(`https://kidsauszeit.at:5000/api/events/${selectedEvent.id}`);
      setEvents(events.filter(evt => evt.id !== selectedEvent.id));
      closeModal();
      setShowViewModal(false);
    } catch (err) {
      console.error('Fehler beim Löschen:', err);
    }
  };

  const startEdit = () => {
    if (!selectedEvent) return;
    setEditMode(true);
    setNewEvent({
      title: selectedEvent.title,
      description: selectedEvent.extendedProps?.description || '',
      start: new Date(selectedEvent.start).toISOString().slice(0, 16),
      end: selectedEvent.end ? new Date(selectedEvent.end).toISOString().slice(0, 16) : '',
      allDay: selectedEvent.allDay,
      color: selectedEvent.backgroundColor || '#3788d8'
    });
    setCalendarVisible(false);
    setShowViewModal(false);
    setShowCreateModal(true);
  };

  const handleDateClick = (arg) => {
    if (rank === 'admin') {
      const now = new Date();
      const date = new Date(arg.date.getTime() - arg.date.getTimezoneOffset() * 60000);
      const isoString = date.toISOString().slice(0, 16);
      if (date < now.setHours(0, 0, 0, 0)) return;
      setNewEvent({
        title: '',
        description: '',
        start: isoString,
        end: '',
        allDay: arg.allDay,
        color: '#3788d8'
      });
      setCalendarVisible(false);
      setShowCreateModal(true);
    }
  };

  const handleEventDrop = async (changeInfo) => {
    const updatedEvent = changeInfo.event;
    try {
      await axios.put(`https://kidsauszeit.at:5000/api/events/${updatedEvent.id}`, {
        startDateTime: updatedEvent.start.toISOString(),
        endDateTime: updatedEvent.end?.toISOString(),
      });
      setEvents(events.map(evt => evt.id === updatedEvent.id ? { ...evt, start: updatedEvent.start, end: updatedEvent.end } : evt));
    } catch (err) {
      console.error('Fehler beim Verschieben:', err);
      changeInfo.revert();
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewEvent(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setValidationError('');
  };

  const handleCreateEvent = async () => {
    setTriedSubmit(true);
    try {
      const now = new Date();
      const start = new Date(newEvent.start);
      const end = new Date(newEvent.end);

      if (!newEvent.title || !newEvent.start || !newEvent.end) return;

      if (start < now.setHours(0, 0, 0, 0)) {
        setValidationError('Das Startdatum darf nicht in der Vergangenheit liegen.');
        return;
      }

      if (end < start) {
        setValidationError('Das Enddatum muss gleich oder nach dem Startdatum liegen.');
        return;
      }

      const payload = {
        title: newEvent.title,
        details: newEvent.description,
        startDateTime: newEvent.start,
        endDateTime: newEvent.end,
        color: newEvent.color,
        allDay: newEvent.allDay,
        user: user?.username || 'Unbekannt'
      };

      if (editMode && selectedEvent) {
        await axios.put(`https://kidsauszeit.at:5000/api/events/${selectedEvent.id}`, payload);
        setEvents(events.map(evt => evt.id === selectedEvent.id
          ? {
              ...evt,
              ...payload,
              start: payload.startDateTime,
              end: payload.endDateTime,
              extendedProps: {
                description: payload.details,
                user: payload.user
              }
            }
          : evt));
      } else {
        const res = await axios.post('https://kidsauszeit.at:5000/api/events', payload);
        setEvents(prev => [...prev, {
          id: res.data.id,
          title: payload.title,
          start: payload.startDateTime,
          end: payload.endDateTime,
          color: payload.color,
          allDay: payload.allDay,
          extendedProps: { description: payload.details, user: payload.user }
        }]);
      }
      setShowCreateModal(false);
      setCalendarVisible(true);
      setNewEvent({ title: '', description: '', start: '', end: '', allDay: false, color: '#3788d8' });
      setEditMode(false);
      setSelectedEvent(null);
      setTriedSubmit(false);
    } catch (err) {
      console.error('Fehler beim Speichern des Events:', err);
    }
  };

  const now = new Date();
  const nowStringDate = now.toISOString().split('T')[0];
  const nowStringDateTime = now.toISOString().slice(0, 16);

  const inputStyle = (name) => ({
    color: "#fff",
    backgroundColor: "#2b2b2b",
    borderColor: triedSubmit && !newEvent[name] ? "red" : "#555",
    fontSize: '1.6rem',
    padding: '12px 16px',
    height: '60px',
    borderRadius: '8px',
    transition: 'border-color 0.3s ease',
    width: '100%'
  });

  const isFormValid = newEvent.title && newEvent.start && newEvent.end;

  return (
    <>
      <Modal show={calendarVisible} onHide={onClose} dialogClassName="calendar-modal">
        <Modal.Header closeButton>
          <Modal.Title>Event Kalender</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {rank === 'admin' && (
            <Button variant="success" onClick={() => { setCalendarVisible(false); setShowCreateModal(true); }} className="mb-3">
              Event erstellen
            </Button>
          )}

          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            events={events}
            eventClick={handleEventClick}
            dateClick={handleDateClick}
            eventDrop={handleEventDrop}
            editable={rank === 'admin'}
            height="auto"
            locale={deLocale}
            firstDay={1}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Beschreibung:</strong> {selectedEvent?.extendedProps?.description}</p>
          <p><strong>Start:</strong> {new Date(selectedEvent?.start).toLocaleString()}</p>
          <p><strong>Ende:</strong> {selectedEvent?.end ? new Date(selectedEvent.end).toLocaleString() : 'Nicht gesetzt'}</p>
          <p><strong>Erstellt von:</strong> {selectedEvent?.extendedProps?.user || 'Unbekannt'}</p>
        </Modal.Body>
        <Modal.Footer>
          {rank === 'admin' && (
            <>
              <Button variant="danger" onClick={handleDeleteEvent}>Löschen</Button>
              <Button variant="primary" onClick={startEdit}>Bearbeiten</Button>
            </>
          )}
          <Button variant="secondary" onClick={() => setShowViewModal(false)}>Schließen</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreateModal} onHide={() => { setShowCreateModal(false); setEditMode(false); setCalendarVisible(true); }} dialogClassName="create-event-modal">
        <Modal.Header closeButton>
          <Modal.Title>{editMode ? 'Event bearbeiten' : 'Neues Event erstellen'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="responsive-form">
            <Form.Group className="mb-3 w-100">
              <Form.Label>Titel</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={newEvent.title}
                onChange={handleInputChange}
                placeholder="Event-Titel"
                style={inputStyle('title')}
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                name="description"
                value={newEvent.description}
                onChange={handleInputChange}
                placeholder="Details zum Event"
                style={inputStyle('description')}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" label="Ganztägig" name="allDay" checked={newEvent.allDay} onChange={handleInputChange} />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Start</Form.Label>
              <Form.Control
                type={newEvent.allDay ? 'date' : 'datetime-local'}
                name="start"
                value={newEvent.start}
                onChange={handleInputChange}
                min={newEvent.allDay ? nowStringDate : nowStringDateTime}
                style={inputStyle('start')}
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Ende</Form.Label>
              <Form.Control
                type={newEvent.allDay ? 'date' : 'datetime-local'}
                name="end"
                value={newEvent.end}
                onChange={handleInputChange}
                min={newEvent.start}
                style={inputStyle('end')}
              />
              {validationError && <Form.Text className="text-danger">{validationError}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Farbe</Form.Label>
              <Form.Control
                type="color"
                name="color"
                value={newEvent.color}
                onChange={handleInputChange}
                style={{ width: '100%', height: '60px', padding: 0, border: 'none' }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { setShowCreateModal(false); setEditMode(false); setCalendarVisible(true); setTriedSubmit(false); }}>Abbrechen</Button>
          <Button variant="primary" onClick={handleCreateEvent} disabled={!isFormValid}>Speichern</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EventCalendar;
