import { useState, createContext, useEffect } from 'react';
import CNavbar from './Navbar';
import Footer from './Footer';
import './main.css'; 
import verein from './img/Verein.png';
import stall from './img/stall.png';
import axios from 'axios';

// Erstellen Sie den Context
const UserContext = createContext();

const MainPage = () => {

  const loginWithToken = async (token) => {
    try {
      const response = await axios.post('https://kidsauszeit.at:5000/loginWithToken', { token });
      const { success, username: user, rank } = response.data;
      if (success) {
        handleUserLogin(user, rank);
      }
    } catch (error) {
      console.error('Anmeldung mit Token fehlgeschlagen:', error);
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const isDeviceExcluded = () => {
    const excludedDeviceToken = 'excludeDevice'; // Token zur Identifizierung ausgeschlossener Geräte
    const deviceToken = localStorage.getItem('deviceToken') || getCookie('deviceToken');
    return deviceToken === excludedDeviceToken;
  };

  useEffect(() => {
    const trackPageView = async () => {
      if (!isDeviceExcluded()) {
        axios.post('https://kidsauszeit.at:5000/trackPageView');
      }
    };

    trackPageView();

    const token = getCookie('token');
    if (token) {
      loginWithToken(token);
    }

    const handleUnload = () => {
      localStorage.removeItem('user');
      localStorage.removeItem('rank');
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [rank, setRank] = useState(() => {
    const storedRank = localStorage.getItem('rank');
    return storedRank ? JSON.parse(storedRank) : 'user';
  });

  const handleUserLogin = (loggedInUser, userRank) => {
    setUser(loggedInUser);
    setRank(userRank);
    localStorage.setItem('user', JSON.stringify(loggedInUser));
    localStorage.setItem('rank', JSON.stringify(userRank));
  };

  const handleUserLogout = () => {
    setUser(null);
    setRank(null);
    localStorage.removeItem('user');
    localStorage.removeItem('rank');
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  return (
    <UserContext.Provider value={{ user, rank }}>
      <CNavbar onUserLogin={handleUserLogin} onUserLogout={handleUserLogout}/>
      <div className="willkommen">
        <div className="welcome" id="nichtmarkieren">
          <img className="bilder" src={verein} alt="Verein" /> Kids Auszeit e.V. <img className="bilder" src={stall} alt="Stall" />

        </div>
        {user && <div className="usser" id="nichtmarkieren">Willkommen {user}</div> }
      </div>

      {/* <div className="teamtext">Bianka Malek - Kids Auszeit</div> */}
      <div className='teams'></div> 
      <Footer/>
    </UserContext.Provider>
  );
};

export { MainPage, UserContext };
